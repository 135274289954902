import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
					<path d="M1460 3637 c0 -7 13 -18 29 -24 16 -6 38 -26 48 -44 17 -32 18 -71
21 -548 3 -567 0 -607 -48 -643 -16 -12 -27 -25 -24 -30 7 -12 234 -10 234 2
0 5 -17 18 -39 29 -27 14 -45 34 -62 68 l-24 48 -3 485 c-3 422 -1 483 11 470
13 -13 663 -859 798 -1037 94 -124 88 -160 91 525 3 531 5 601 20 634 9 20 26
39 37 43 12 3 21 13 21 21 0 11 -23 14 -120 14 -100 0 -120 -2 -120 -15 0 -8
6 -15 14 -15 23 0 77 -51 95 -90 14 -30 16 -91 19 -410 2 -206 2 -408 0 -449
l-3 -73 -405 526 -405 526 -92 0 c-69 0 -93 -3 -93 -13z"/>
<path d="M2540 2900 c0 -7 11 -21 24 -31 37 -29 46 -89 46 -318 l0 -211 75 0
75 0 0 270 0 270 100 0 c254 0 443 -92 561 -273 33 -52 76 -160 93 -239 6 -28
7 -28 91 -28 l85 0 0 29 c0 51 -48 185 -88 247 -82 126 -221 221 -387 265 -71
19 -115 22 -377 27 -230 3 -298 1 -298 -8z"/>
<path d="M1321 2190 l2 -90 28 0 c27 0 29 2 29 43 l1 42 37 -43 c23 -26 45
-42 60 -42 21 0 22 3 22 90 0 89 0 90 -25 90 -23 0 -25 -4 -25 -42 l-1 -43
-37 43 c-27 31 -44 42 -65 42 l-28 0 2 -90z"/>
<path d="M1610 2210 c0 -87 17 -110 84 -110 70 0 89 23 94 109 l4 71 -30 0
c-31 0 -31 0 -34 -61 -2 -45 -8 -65 -20 -73 -30 -19 -46 13 -47 97 -1 33 -4
37 -26 37 -24 0 -25 -2 -25 -70z"/>
<path d="M1890 2190 l0 -90 30 0 c28 0 30 3 30 35 0 19 5 35 10 35 6 0 18 -16
27 -35 14 -30 22 -35 51 -35 l34 0 -21 36 c-20 33 -20 38 -6 54 19 21 19 54
-1 74 -11 12 -35 16 -85 16 l-69 0 0 -90z m110 35 c0 -9 -9 -15 -25 -15 -16 0
-25 6 -25 15 0 9 9 15 25 15 16 0 25 -6 25 -15z"/>
<path d="M2194 2233 c-9 -27 -23 -63 -31 -82 -7 -18 -13 -37 -13 -42 0 -15 58
-10 64 6 3 8 17 15 31 15 14 0 28 -7 31 -15 6 -16 64 -21 64 -6 0 5 -6 24 -13
42 -8 19 -22 55 -31 82 -16 43 -20 47 -51 47 -31 0 -35 -4 -51 -47z m66 -50
c0 -7 -7 -13 -15 -13 -16 0 -19 9 -9 35 5 13 8 14 15 3 5 -7 9 -19 9 -25z"/>
<path d="M2430 2190 l0 -90 59 0 c82 0 111 23 111 90 0 67 -29 90 -111 90
l-59 0 0 -90z m105 34 c27 -27 5 -83 -32 -84 -9 0 -13 17 -13 50 0 54 14 65
45 34z"/>
<path d="M2734 2233 c-9 -27 -23 -63 -31 -82 -7 -18 -13 -37 -13 -42 0 -15 58
-10 64 6 3 8 17 15 31 15 14 0 28 -7 31 -15 6 -16 64 -21 64 -6 0 5 -6 24 -13
42 -8 19 -22 55 -31 82 -16 43 -20 47 -51 47 -31 0 -35 -4 -51 -47z m65 -55
c1 -5 -6 -8 -14 -8 -16 0 -19 9 -8 36 6 16 7 16 14 -2 4 -10 8 -22 8 -26z"/>
<path d="M2960 2255 c0 -21 5 -25 30 -25 l30 0 0 -65 0 -65 30 0 30 0 0 64 c0
63 1 65 28 68 19 2 28 9 30 26 3 21 1 22 -87 22 -90 0 -91 0 -91 -25z"/>
<path d="M3243 2190 l-32 -90 28 0 c16 0 31 7 35 15 3 9 18 15 35 15 16 0 31
-6 35 -15 3 -9 18 -15 35 -15 l30 0 -32 90 -32 90 -35 0 -35 0 -32 -90z m80 0
c4 -15 0 -20 -15 -20 -14 0 -18 5 -14 16 3 9 6 20 6 25 0 17 17 1 23 -21z"/>
<path d="M3510 2262 c-18 -15 -22 -25 -16 -46 7 -27 26 -39 79 -50 34 -8 36
-26 2 -26 -14 0 -25 5 -25 10 0 6 -13 10 -30 10 -19 0 -30 -5 -30 -14 0 -44
113 -63 150 -26 39 39 17 78 -53 95 -42 11 -47 29 -6 22 19 -3 42 -8 52 -12
22 -9 23 17 1 39 -22 22 -96 21 -124 -2z"/>
<path d="M3750 2215 c0 -57 3 -69 25 -90 18 -19 35 -25 70 -25 68 0 85 22 85
110 l0 70 -30 0 c-29 0 -29 0 -32 -67 -3 -65 -4 -68 -28 -68 -24 0 -25 3 -28
68 -3 67 -3 67 -32 67 l-30 0 0 -65z"/>
<path d="M4040 2190 l0 -90 69 0 c50 0 74 4 85 16 20 19 20 48 1 64 -13 11
-13 14 0 27 41 41 4 73 -86 73 l-69 0 0 -90z m110 35 c0 -10 -10 -15 -30 -15
-20 0 -30 5 -30 15 0 10 10 15 30 15 20 0 30 -5 30 -15z m0 -70 c0 -9 -9 -15
-25 -15 -16 0 -25 6 -25 15 0 9 9 15 25 15 16 0 25 -6 25 -15z"/>
<path d="M2610 1908 c0 -90 -5 -181 -10 -203 -10 -46 -54 -90 -79 -79 -9 4
-81 29 -161 56 -181 62 -259 114 -309 209 -18 33 -31 62 -29 64 7 8 122 -51
156 -80 38 -32 62 -74 62 -110 0 -14 4 -25 9 -25 12 0 -5 75 -24 104 -20 31
-65 62 -138 97 -60 28 -87 35 -87 23 0 -3 14 -33 32 -66 28 -52 55 -81 151
-159 4 -3 -34 2 -85 12 -51 10 -136 21 -188 25 -52 3 -98 8 -101 10 -4 2 -9
17 -12 33 -9 41 -66 96 -123 115 -27 9 -80 19 -118 23 l-71 6 44 -51 c40 -48
82 -74 191 -119 20 -8 8 -11 -71 -17 -52 -4 -122 -13 -154 -21 l-59 -13 -44
31 c-39 29 -52 32 -111 31 -50 0 -82 -7 -134 -30 -38 -16 -69 -34 -69 -39 0
-6 26 -18 58 -28 58 -18 147 -18 214 0 21 6 18 -1 -27 -53 -28 -32 -56 -69
-63 -82 -18 -35 -42 -123 -36 -130 18 -17 162 99 189 152 13 24 20 56 19 87
-1 48 0 49 36 58 109 29 422 42 492 21 14 -5 20 -8 14 -9 -6 -1 -45 -23 -85
-49 -54 -36 -84 -64 -112 -106 -21 -32 -36 -60 -34 -63 8 -7 118 27 168 53 53
26 96 73 105 112 12 54 12 54 57 47 60 -9 148 -31 250 -62 l88 -27 -86 -17
c-81 -17 -141 -43 -195 -87 -14 -11 -21 -24 -17 -28 5 -5 52 -9 105 -9 115 0
166 21 208 85 15 22 28 40 30 40 3 0 24 -9 48 -21 43 -20 57 -20 353 -16 342
4 367 8 501 75 92 45 178 123 231 207 38 60 81 153 81 175 0 6 -33 10 -73 10
l-73 0 -16 -70 c-42 -178 -133 -287 -287 -342 -45 -17 -89 -22 -217 -25 -244
-8 -244 -8 -244 270 l0 167 -75 0 -75 0 0 -162z m-936 15 c48 -19 82 -47 102
-85 30 -58 16 -60 -94 -18 -60 23 -89 41 -127 81 l-50 51 64 -6 c35 -4 82 -14
105 -23z m-294 -153 c49 -35 43 -40 -64 -59 -33 -6 -73 -11 -89 -11 -42 0
-141 30 -130 40 4 5 42 20 83 35 87 30 153 28 200 -5z m630 -53 c-11 -50 -38
-85 -86 -113 -42 -25 -164 -67 -164 -57 0 10 59 89 81 109 28 25 148 94 165
94 8 0 10 -11 4 -33z m-589 -19 c4 -71 -11 -106 -68 -159 -39 -37 -115 -91
-120 -85 -2 1 7 31 18 65 17 50 36 79 87 132 35 38 68 69 73 69 5 0 9 -10 10
-22z m1009 -72 c0 -7 -17 -29 -39 -49 -53 -48 -138 -66 -233 -51 l-38 7 38 27
c57 41 78 50 145 65 84 18 127 19 127 1z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
